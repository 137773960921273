.section__header {
  display: table;
  width: auto;
  cursor: pointer;
  margin: 1em 0;
}

.section__header h3,
.section__header .icon {
  display: table-cell;
  white-space: nowrap;
  padding-left: 0;
}

.section__header .icon {
  transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: rotate(-90deg);
}

.section__header.active .icon {
  transform: rotate(0deg);
}

.section__header h3 {
  font-size: 1.2em;
  line-height: 1.5;
  text-transform: uppercase;
  padding-left: 0.15em;
}

.net-worth__total {
  display: table-cell;
  white-space: nowrap;
  text-align: right;
  font-size: 1.2em;
  width: 100%;
}

@media (max-width: 767px) {
  .section__header {
    padding-left: 0.5em;
    box-sizing: border-box;
    border-top: solid 1px rgba(0, 0, 0, 0.15);
    width: 100%;
  }

  .net-worth__total {
    padding-right: 0.8em;
    font-weight: 700;
  }
}
